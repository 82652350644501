h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #30343b;
}

h2 {
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  font-size: 2.0rem;
  margin-bottom: 0rem;
  line-height: 2.7rem;
}

h3 {
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  font-size: 1.2rem;
  margin-bottom: 0.875rem;
  line-height: 2.3625000000000003rem;
}

h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  line-height: 2.0250000000000004rem;
}

small {
  font-style: italic;
}

body {
  line-height: 1.5rem;
  font-family: Montserrat,sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 16px;
  color: #343a40;
  background-color: #fff;
}

.App {
  background-color: #f8f9fa;
  box-shadow: -3px 0px 10px 1px #eee;
  padding: 30px 50px;
  border-radius: 4px;
}

div.loading {
  cursor: progress;
}

.box{
  margin-bottom: 15px;
}

input[type="text"], select {
  width: 100%;
  padding: 0 10px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  border: 1px solid #87909e;
}

button{
  background-color: #fe5102;
  border: none;
  border-radius: 4px;
  min-width: 140px;
  height: 40px;
  color: #fff;
  font-weight: bold;
  padding: 0 30px;
}

button:disabled{
  background-color: #666; 
}

button.loading{
  background-color: #666;
  pointer-events: none;
}

input:read-only{
  background-color: #666;
}

.panel .box{
  margin-bottom: 10px;
}

.panel input[type="text"],
.panel select {
  border-radius: 8px;
  background-color: #fff;
}

input[type="checkbox"] {
  width: 50px;
  height: 50px;
  vertical-align: middle;
  margin-right: 15px;
}

input[type="range"] {
  width: 100%;
  background-color: transparent;
}

ul > li{
  color: #87909e;
  margin-bottom: 15px;
}

.maxValue{
  float: right;
}